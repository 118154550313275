.float-cart {
  position: fixed;
  top: 0;
  right: -450px;
  width: 450px;
  height: 100%;
  background-color: #1b1a20;
  box-sizing: border-box;

  transition: right 0.2s;

  &--open {
    right: 0;
  }

  &__close-btn {
    width: 50px;
    height: 50px;
    color: #ececec;
    background-color: #1b1a20;
    text-align: center;
    line-height: 50px;
    position: absolute;
    top: 0;
    left: -50px;
    cursor: pointer;

    &:hover {
      background-color: #212027;
    }
  }

  .bag {
    width: 40px;
    height: 40px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
    background-image: url('../../../static/bag-icon.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    &--float-cart-closed {
      position: absolute;
      background-color: #000;
      background-size: 50%;
      left: -60px;
      width: 60px;
      height: 60px;
      cursor: pointer;

      .bag__quantity {
        bottom: 5px;
        right: 10px;
      }
    }

    &__quantity {
      display: inline-block;
      width: 18px;
      height: 18px;
      color: #0c0b10;
      font-weight: bold;
      font-size: 0.7em;
      text-align: center;
      line-height: 18px;
      border-radius: 50%;
      background-color: #eabf00;
      position: absolute;
      bottom: -5px;
      right: 0px;
    }
  }

  &__header {
    color: #ececec;
    box-sizing: border-box;
    text-align: center;
    padding: 45px 0;

    .header-title {
      font-weight: bold;
      font-size: 1.2em;
      vertical-align: middle;
    }
  }

  &__shelf-container {
    position: relative;
    min-height: 280px;
    // padding-bottom: 200px;

    .shelf-empty {
      color: #ececec;
      text-align: center;
      line-height: 40px;
    }

    .shelf-item {
      position: relative;
      box-sizing: border-box;
      padding: 5%;

      transition: background-color 0.2s, opacity 0.2s;

      &::before {
        content: '';
        width: 90%;
        height: 2px;
        background-color: rgba(0, 0, 0, 0.2);
        position: absolute;
        top: 0;
        left: 5%;
      }

      &--mouseover {
        background: #0c0b10;
        .shelf-item__details {
          .title,
          .desc {
            text-decoration: line-through;
            opacity: 0.6;
          }
        }

        .shelf-item__price {
          text-decoration: line-through;
          opacity: 0.6;
        }
      }

      &__del {
        width: 16px;
        height: 16px;
        top: 15px;
        right: 5%;
        border-radius: 50%;
        position: absolute;
        background-size: auto 100%;
        background-image: url('../../../static/sprite_delete-icon.png');
        background-repeat: no-repeat;
        z-index: 2;
        cursor: pointer;

        &:hover {
          background-position-x: -17px;
        }
      }

      &__thumb,
      &__details,
      &__price {
        display: inline-block;
        vertical-align: middle;
      }

      &__thumb {
        vertical-align: middle;
        width: 15%;
        margin-right: 3%;

        img {
          width: 100%;
          height: auto;
        }
      }
      &__details {
        width: 57%;

        .title {
          color: #ececec;
          margin: 0;
        }

        .desc {
          color: #5b5a5e;
          margin: 0;
        }
      }
      &__price {
        color: #eabf00;
        text-align: right;
        width: 25%;
      }
      .change-product-button {
        color: #b7b7b7;
        border: 0;
        background-color: #000;
        width: 25px;
        height: 25px;

        &:disabled {
          opacity: .2;
        }
      }
    }
  }

  &__footer {
    box-sizing: border-box;
    padding: 5%;
    position: absolute;
    bottom: 0;
    width: 100%;
    min-height: 200px;
    z-index: 2;
    background-color: #1b1a20;

    &::before {
      content: '';
      width: 100%;
      height: 20px;
      display: block;
      position: absolute;
      top: -20px;
      left: 0;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.2), transparent);
    }

    .sub,
    .sub-price {
      color: #5b5a5e;
      vertical-align: middle;
      display: inline-block;
    }

    .sub {
      width: 20%;
    }

    .sub-price {
      width: 80%;
      text-align: right;

      &__val,
      &__installment {
        margin: 0;
      }

      &__val {
        color: #eabf00;
        font-size: 22px;
      }
    }

    .buy-btn {
      color: #ececec;
      text-transform: uppercase;
      background-color: #0c0b10;
      text-align: center;
      padding: 15px 0;
      margin-top: 40px;
      cursor: pointer;

      transition: background-color 0.2s;

      &:hover {
        background-color: #000;
      }
    }
  }
}


/* MAC scrollbar para desktop*/
@media screen and (min-width: 640px) {
  .float-cart__content::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 10px;
  }
  .float-cart__content::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #0c0b10;
  }
}

.float-cart__content {
  height: 100%;
  overflow-y: scroll;
}
