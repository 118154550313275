.home-block {
  padding: 0 20px;
  max-width: 1200px;
  margin: 0px auto;
}

.home-block img {
  height: 200px;
  padding: 30px 0;
}

.home-block p {
  font-size: larger;
  padding: 0;
}

.home-block h3 {
  margin: 2em 0 0.5em 0;
}
