.footer-block {
  padding: 20px;
  max-width: 1200px;
  margin: 20px auto;
}

.footer-block p {
  padding: 0;
}

.footer-block h3 {
  margin: 2em 0 0.5em 0;
}

.footer-block a {
  color: #000;
}