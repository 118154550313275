.order-form {
  display: inline;
}

.order-form label {
  color: #ececec;
}

.form-group {
  margin-bottom: 1rem;
  padding: 0 20px;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

.form-control {
  display: block;
  width: 95%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ececec;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

button {
  width: 100%;
  border: none;
}

.order-form input[disabled] {
  color: #aaa;
}

.spinner-container {
  color: #ececec;
  padding: 15px 0;
  margin-top: 40px;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

.spinner {
  float: right;
}
