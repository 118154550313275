@import url(https://fonts.googleapis.com/css?family=Roboto);
.spinner.lds-ring {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -32px;
  margin-top: -32px;
  width: 64px;
  height: 64px;
  z-index: 10;
  border-radius: 5px;
  background-color: #000; }
  .spinner.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid #fff;
    border-radius: 50%;
    -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent; }
    .spinner.lds-ring div:nth-child(1) {
      -webkit-animation-delay: -0.45s;
              animation-delay: -0.45s; }
    .spinner.lds-ring div:nth-child(2) {
      -webkit-animation-delay: -0.3s;
              animation-delay: -0.3s; }
    .spinner.lds-ring div:nth-child(3) {
      -webkit-animation-delay: -0.15s;
              animation-delay: -0.15s; }

@-webkit-keyframes lds-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes lds-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.shelf-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 600px; }
  .shelf-container-header {
    width: 100%;
    margin-bottom: 10px; }
    .shelf-container-header .products-found {
      float: left;
      margin: 0;
      margin-top: 8px; }
    .shelf-container-header .sort {
      float: right; }
      .shelf-container-header .sort select {
        background-color: #fff;
        outline: none;
        border: 1px solid #ececec;
        border-radius: 2px;
        margin-left: 10px;
        width: auto;
        height: 35px;
        cursor: pointer; }
        .shelf-container-header .sort select:hover {
          border: 1px solid #5b5a5e; }
  .shelf-container .shelf-item {
    width: 33%;
    position: relative;
    text-align: center;
    box-sizing: border-box;
    padding: 10px;
    margin-bottom: 30px;
    border: 1px solid transparent;
    cursor: pointer; }
    .shelf-container .shelf-item:hover {
      border: 1px solid #eee; }
      .shelf-container .shelf-item:hover .shelf-item__buy-btn {
        background-color: #eabf00; }
    .shelf-container .shelf-item .shelf-stopper {
      position: absolute;
      color: #ececec;
      top: 10px;
      right: 10px;
      padding: 5px;
      font-size: 0.6em;
      background-color: #1b1a20;
      cursor: default; }
    .shelf-container .shelf-item__thumb img {
      width: 100%; }
    .shelf-container .shelf-item__title {
      position: relative;
      padding: 0 20px;
      height: 45px; }
      .shelf-container .shelf-item__title::before {
        content: '';
        width: 20px;
        height: 2px;
        background-color: #eabf00;
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -10px; }
    .shelf-container .shelf-item__detail {
      text-align: center;
      min-height: 150px;
      padding-bottom: 20px; }
    .shelf-container .shelf-item__price {
      height: 60px; }
      .shelf-container .shelf-item__price .val b {
        font-size: 1.5em;
        margin-left: 5px; }
      .shelf-container .shelf-item__price .installment {
        color: #9c9b9b; }
    .shelf-container .shelf-item__buy-btn {
      background-color: #1b1a20;
      color: #fff;
      padding: 15px 0;
      margin-top: 10px;
      cursor: pointer;
      transition: background-color 0.2s; }

.order-form {
  display: inline; }

.order-form label {
  color: #ececec; }

.form-group {
  margin-bottom: 1rem;
  padding: 0 20px; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

.form-control {
  display: block;
  width: 95%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ececec;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

button {
  width: 100%;
  border: none; }

.order-form input[disabled] {
  color: #aaa; }

.spinner-container {
  color: #ececec;
  padding: 15px 0;
  margin-top: 40px;
  transition: background-color 0.2s; }

.spinner {
  float: right; }

.float-cart {
  position: fixed;
  top: 0;
  right: -450px;
  width: 450px;
  height: 100%;
  background-color: #1b1a20;
  box-sizing: border-box;
  transition: right 0.2s; }
  .float-cart--open {
    right: 0; }
  .float-cart__close-btn {
    width: 50px;
    height: 50px;
    color: #ececec;
    background-color: #1b1a20;
    text-align: center;
    line-height: 50px;
    position: absolute;
    top: 0;
    left: -50px;
    cursor: pointer; }
    .float-cart__close-btn:hover {
      background-color: #212027; }
  .float-cart .bag {
    width: 40px;
    height: 40px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAQAAADZc7J/AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfiCwwQEgYn7+gWAAABQklEQVRIx9WTsUoDQRRF72xMYSFokcRCjBFESGWRwtZe8AtEFFNrI1bB0tLGQuzs/AJB8AO2kZRCxBiRQNKIoIYoyLFwCZuss9nNgpBXzc7be3j3zow09mU48321zVF8gNtbZ5Q1UwmGYQPIxFU5vnVdUuHfARO+OD5oa9GzM6NcbICkem+CLZ0kA1zKHQ2w6tlpqRUN4AwA5knFiTAISGsuHqDfwoOkgp4kUkoP1WI+Azt02ZYk9hle3cAEBh69c7iKEOJ30IJU/71KpqbaKCH6b0LEGgTcaYXpeIj+GJf54pyI70CSTACxq1M5ehmqvDCHfwIkFrQW4S1WzXUCq+E5lNhkMqSfo0ze3t7hlWeqIfJ3GnQo2n644ZhZYMnSL9OQcKnYAHs0ueUeY+nn6eDyRskGcFjngGxIBkUqVvk41g+oBJ136GBf8AAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOC0xMS0xMlQxNjoxODowNiswMTowMEVm3zEAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTgtMTEtMTJUMTY6MTg6MDYrMDE6MDA0O2eNAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center; }
    .float-cart .bag--float-cart-closed {
      position: absolute;
      background-color: #000;
      background-size: 50%;
      left: -60px;
      width: 60px;
      height: 60px;
      cursor: pointer; }
      .float-cart .bag--float-cart-closed .bag__quantity {
        bottom: 5px;
        right: 10px; }
    .float-cart .bag__quantity {
      display: inline-block;
      width: 18px;
      height: 18px;
      color: #0c0b10;
      font-weight: bold;
      font-size: 0.7em;
      text-align: center;
      line-height: 18px;
      border-radius: 50%;
      background-color: #eabf00;
      position: absolute;
      bottom: -5px;
      right: 0px; }
  .float-cart__header {
    color: #ececec;
    box-sizing: border-box;
    text-align: center;
    padding: 45px 0; }
    .float-cart__header .header-title {
      font-weight: bold;
      font-size: 1.2em;
      vertical-align: middle; }
  .float-cart__shelf-container {
    position: relative;
    min-height: 280px; }
    .float-cart__shelf-container .shelf-empty {
      color: #ececec;
      text-align: center;
      line-height: 40px; }
    .float-cart__shelf-container .shelf-item {
      position: relative;
      box-sizing: border-box;
      padding: 5%;
      transition: background-color 0.2s, opacity 0.2s; }
      .float-cart__shelf-container .shelf-item::before {
        content: '';
        width: 90%;
        height: 2px;
        background-color: rgba(0, 0, 0, 0.2);
        position: absolute;
        top: 0;
        left: 5%; }
      .float-cart__shelf-container .shelf-item--mouseover {
        background: #0c0b10; }
        .float-cart__shelf-container .shelf-item--mouseover .shelf-item__details .title,
        .float-cart__shelf-container .shelf-item--mouseover .shelf-item__details .desc {
          text-decoration: line-through;
          opacity: 0.6; }
        .float-cart__shelf-container .shelf-item--mouseover .shelf-item__price {
          text-decoration: line-through;
          opacity: 0.6; }
      .float-cart__shelf-container .shelf-item__del {
        width: 16px;
        height: 16px;
        top: 15px;
        right: 5%;
        border-radius: 50%;
        position: absolute;
        background-size: auto 100%;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAOCAYAAADT0Rc6AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDowMzgwMTE3NDA3MjA2ODExODA4MzlFRjgwMkJGMENDMSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo0NzRFMzQ0QjI3MzgxMUU4QjRFMUVBNEJCODU5RDAzMSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo0NzRFMzQ0QTI3MzgxMUU4QjRFMUVBNEJCODU5RDAzMSIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M2IChNYWNpbnRvc2gpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6RUE3RjExNzQwNzIwNjgxMUIxQTQ5QTgyNkJBMzJBOEUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MDM4MDExNzQwNzIwNjgxMTgwODM5RUY4MDJCRjBDQzEiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5cNiR0AAAA50lEQVR42qSUaw6EMAiEYY8rB7JcVrYoNaQCNbsk88N2ypc+HICrtq69CyEvNM8mIuCk33sXTuNeaJ5zrRZ1HV361RIw2pyYd4Cp65CrWgJGmxPz6gbvZpKAMfJYw9FMEjBGntGUE3AEVC+6ppyAI6B60e8mAldAWIBD4LjTCvwAqjG5txkcAmdoBWZ/z8UL5RVQ9YF3JfB7lWtXx9v+ON4WHW8E5GTszUPiZOx+SBkQq7kFEKs5yH6LxbFn4cBBOHAUDj4GuYhBdjFIQQxyEYPsYpBGUypyd45DmppSkbtzHJ5rvwIMAKXLCXxfiHXkAAAAAElFTkSuQmCC);
        background-repeat: no-repeat;
        z-index: 2;
        cursor: pointer; }
        .float-cart__shelf-container .shelf-item__del:hover {
          background-position-x: -17px; }
      .float-cart__shelf-container .shelf-item__thumb, .float-cart__shelf-container .shelf-item__details, .float-cart__shelf-container .shelf-item__price {
        display: inline-block;
        vertical-align: middle; }
      .float-cart__shelf-container .shelf-item__thumb {
        vertical-align: middle;
        width: 15%;
        margin-right: 3%; }
        .float-cart__shelf-container .shelf-item__thumb img {
          width: 100%;
          height: auto; }
      .float-cart__shelf-container .shelf-item__details {
        width: 57%; }
        .float-cart__shelf-container .shelf-item__details .title {
          color: #ececec;
          margin: 0; }
        .float-cart__shelf-container .shelf-item__details .desc {
          color: #5b5a5e;
          margin: 0; }
      .float-cart__shelf-container .shelf-item__price {
        color: #eabf00;
        text-align: right;
        width: 25%; }
      .float-cart__shelf-container .shelf-item .change-product-button {
        color: #b7b7b7;
        border: 0;
        background-color: #000;
        width: 25px;
        height: 25px; }
        .float-cart__shelf-container .shelf-item .change-product-button:disabled {
          opacity: .2; }
  .float-cart__footer {
    box-sizing: border-box;
    padding: 5%;
    position: absolute;
    bottom: 0;
    width: 100%;
    min-height: 200px;
    z-index: 2;
    background-color: #1b1a20; }
    .float-cart__footer::before {
      content: '';
      width: 100%;
      height: 20px;
      display: block;
      position: absolute;
      top: -20px;
      left: 0;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.2), transparent); }
    .float-cart__footer .sub,
    .float-cart__footer .sub-price {
      color: #5b5a5e;
      vertical-align: middle;
      display: inline-block; }
    .float-cart__footer .sub {
      width: 20%; }
    .float-cart__footer .sub-price {
      width: 80%;
      text-align: right; }
      .float-cart__footer .sub-price__val, .float-cart__footer .sub-price__installment {
        margin: 0; }
      .float-cart__footer .sub-price__val {
        color: #eabf00;
        font-size: 22px; }
    .float-cart__footer .buy-btn {
      color: #ececec;
      text-transform: uppercase;
      background-color: #0c0b10;
      text-align: center;
      padding: 15px 0;
      margin-top: 40px;
      cursor: pointer;
      transition: background-color 0.2s; }
      .float-cart__footer .buy-btn:hover {
        background-color: #000; }

/* MAC scrollbar para desktop*/
@media screen and (min-width: 640px) {
  .float-cart__content::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 10px; }
  .float-cart__content::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #0c0b10; } }

.float-cart__content {
  height: 100%;
  overflow-y: scroll; }

.float-cart {
  position: fixed;
  top: 0;
  right: -450px;
  width: 450px;
  height: 100%;
  background-color: #1b1a20;
  box-sizing: border-box;
  transition: right 0.2s; }
  .float-cart--open {
    right: 0; }
  .float-cart__close-btn {
    width: 50px;
    height: 50px;
    color: #ececec;
    background-color: #1b1a20;
    text-align: center;
    line-height: 50px;
    position: absolute;
    top: 0;
    left: -50px;
    cursor: pointer; }
    .float-cart__close-btn:hover {
      background-color: #212027; }
  .float-cart .bag {
    width: 40px;
    height: 40px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAQAAADZc7J/AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfiCwwQEgYn7+gWAAABQklEQVRIx9WTsUoDQRRF72xMYSFokcRCjBFESGWRwtZe8AtEFFNrI1bB0tLGQuzs/AJB8AO2kZRCxBiRQNKIoIYoyLFwCZuss9nNgpBXzc7be3j3zow09mU48321zVF8gNtbZ5Q1UwmGYQPIxFU5vnVdUuHfARO+OD5oa9GzM6NcbICkem+CLZ0kA1zKHQ2w6tlpqRUN4AwA5knFiTAISGsuHqDfwoOkgp4kUkoP1WI+Azt02ZYk9hle3cAEBh69c7iKEOJ30IJU/71KpqbaKCH6b0LEGgTcaYXpeIj+GJf54pyI70CSTACxq1M5ehmqvDCHfwIkFrQW4S1WzXUCq+E5lNhkMqSfo0ze3t7hlWeqIfJ3GnQo2n644ZhZYMnSL9OQcKnYAHs0ueUeY+nn6eDyRskGcFjngGxIBkUqVvk41g+oBJ136GBf8AAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOC0xMS0xMlQxNjoxODowNiswMTowMEVm3zEAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTgtMTEtMTJUMTY6MTg6MDYrMDE6MDA0O2eNAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center; }
    .float-cart .bag--float-cart-closed {
      position: absolute;
      background-color: #000;
      background-size: 50%;
      left: -60px;
      width: 60px;
      height: 60px;
      cursor: pointer; }
      .float-cart .bag--float-cart-closed .bag__quantity {
        bottom: 5px;
        right: 10px; }
    .float-cart .bag__quantity {
      display: inline-block;
      width: 18px;
      height: 18px;
      color: #0c0b10;
      font-weight: bold;
      font-size: 0.7em;
      text-align: center;
      line-height: 18px;
      border-radius: 50%;
      background-color: #eabf00;
      position: absolute;
      bottom: -5px;
      right: 0px; }
  .float-cart__header {
    color: #ececec;
    box-sizing: border-box;
    text-align: center;
    padding: 45px 0; }
    .float-cart__header .header-title {
      font-weight: bold;
      font-size: 1.2em;
      vertical-align: middle; }
  .float-cart__shelf-container {
    position: relative;
    min-height: 280px; }
    .float-cart__shelf-container .shelf-empty {
      color: #ececec;
      text-align: center;
      line-height: 40px; }
    .float-cart__shelf-container .shelf-item {
      position: relative;
      box-sizing: border-box;
      padding: 5%;
      transition: background-color 0.2s, opacity 0.2s; }
      .float-cart__shelf-container .shelf-item::before {
        content: '';
        width: 90%;
        height: 2px;
        background-color: rgba(0, 0, 0, 0.2);
        position: absolute;
        top: 0;
        left: 5%; }
      .float-cart__shelf-container .shelf-item--mouseover {
        background: #0c0b10; }
        .float-cart__shelf-container .shelf-item--mouseover .shelf-item__details .title,
        .float-cart__shelf-container .shelf-item--mouseover .shelf-item__details .desc {
          text-decoration: line-through;
          opacity: 0.6; }
        .float-cart__shelf-container .shelf-item--mouseover .shelf-item__price {
          text-decoration: line-through;
          opacity: 0.6; }
      .float-cart__shelf-container .shelf-item__del {
        width: 16px;
        height: 16px;
        top: 15px;
        right: 5%;
        border-radius: 50%;
        position: absolute;
        background-size: auto 100%;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAOCAYAAADT0Rc6AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDowMzgwMTE3NDA3MjA2ODExODA4MzlFRjgwMkJGMENDMSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo0NzRFMzQ0QjI3MzgxMUU4QjRFMUVBNEJCODU5RDAzMSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo0NzRFMzQ0QTI3MzgxMUU4QjRFMUVBNEJCODU5RDAzMSIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M2IChNYWNpbnRvc2gpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6RUE3RjExNzQwNzIwNjgxMUIxQTQ5QTgyNkJBMzJBOEUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MDM4MDExNzQwNzIwNjgxMTgwODM5RUY4MDJCRjBDQzEiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5cNiR0AAAA50lEQVR42qSUaw6EMAiEYY8rB7JcVrYoNaQCNbsk88N2ypc+HICrtq69CyEvNM8mIuCk33sXTuNeaJ5zrRZ1HV361RIw2pyYd4Cp65CrWgJGmxPz6gbvZpKAMfJYw9FMEjBGntGUE3AEVC+6ppyAI6B60e8mAldAWIBD4LjTCvwAqjG5txkcAmdoBWZ/z8UL5RVQ9YF3JfB7lWtXx9v+ON4WHW8E5GTszUPiZOx+SBkQq7kFEKs5yH6LxbFn4cBBOHAUDj4GuYhBdjFIQQxyEYPsYpBGUypyd45DmppSkbtzHJ5rvwIMAKXLCXxfiHXkAAAAAElFTkSuQmCC);
        background-repeat: no-repeat;
        z-index: 2;
        cursor: pointer; }
        .float-cart__shelf-container .shelf-item__del:hover {
          background-position-x: -17px; }
      .float-cart__shelf-container .shelf-item__thumb, .float-cart__shelf-container .shelf-item__details, .float-cart__shelf-container .shelf-item__price {
        display: inline-block;
        vertical-align: middle; }
      .float-cart__shelf-container .shelf-item__thumb {
        vertical-align: middle;
        width: 15%;
        margin-right: 3%; }
        .float-cart__shelf-container .shelf-item__thumb img {
          width: 100%;
          height: auto; }
      .float-cart__shelf-container .shelf-item__details {
        width: 57%; }
        .float-cart__shelf-container .shelf-item__details .title {
          color: #ececec;
          margin: 0; }
        .float-cart__shelf-container .shelf-item__details .desc {
          color: #5b5a5e;
          margin: 0; }
      .float-cart__shelf-container .shelf-item__price {
        color: #eabf00;
        text-align: right;
        width: 25%; }
      .float-cart__shelf-container .shelf-item .change-product-button {
        color: #b7b7b7;
        border: 0;
        background-color: #000;
        width: 25px;
        height: 25px; }
        .float-cart__shelf-container .shelf-item .change-product-button:disabled {
          opacity: .2; }
  .float-cart__footer {
    box-sizing: border-box;
    padding: 5%;
    position: absolute;
    bottom: 0;
    width: 100%;
    min-height: 200px;
    z-index: 2;
    background-color: #1b1a20; }
    .float-cart__footer::before {
      content: '';
      width: 100%;
      height: 20px;
      display: block;
      position: absolute;
      top: -20px;
      left: 0;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.2), transparent); }
    .float-cart__footer .sub,
    .float-cart__footer .sub-price {
      color: #5b5a5e;
      vertical-align: middle;
      display: inline-block; }
    .float-cart__footer .sub {
      width: 20%; }
    .float-cart__footer .sub-price {
      width: 80%;
      text-align: right; }
      .float-cart__footer .sub-price__val, .float-cart__footer .sub-price__installment {
        margin: 0; }
      .float-cart__footer .sub-price__val {
        color: #eabf00;
        font-size: 22px; }
    .float-cart__footer .buy-btn {
      color: #ececec;
      text-transform: uppercase;
      background-color: #0c0b10;
      text-align: center;
      padding: 15px 0;
      margin-top: 40px;
      cursor: pointer;
      transition: background-color 0.2s; }
      .float-cart__footer .buy-btn:hover {
        background-color: #000; }

/* MAC scrollbar para desktop*/
@media screen and (min-width: 640px) {
  .float-cart__content::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 10px; }
  .float-cart__content::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #0c0b10; } }

.float-cart__content {
  height: 100%;
  overflow-y: scroll; }

.thanks {
  text-align: center;
  color: #ececec;
  display: block;
  margin: auto; }

.thanks img {
  padding: 50px 0; }

.home-block {
  padding: 0 20px;
  max-width: 1200px;
  margin: 0px auto;
}

.home-block img {
  height: 200px;
  padding: 30px 0;
}

.home-block p {
  font-size: larger;
  padding: 0;
}

.home-block h3 {
  margin: 2em 0 0.5em 0;
}

.footer-block {
  padding: 20px;
  max-width: 1200px;
  margin: 20px auto;
}

.footer-block p {
  padding: 0;
}

.footer-block h3 {
  margin: 2em 0 0.5em 0;
}

.footer-block a {
  color: #000;
}
.closed-block {
  padding: 0 20px;
  max-width: 1200px;
  margin: 0px auto;
}

.closed-block img {
  height: 200px;
  padding: 30px 0;
}

.closed-block p {
  font-size: larger;
  padding: 0;
}

.closed-block h3 {
  margin: 2em 0 0.5em 0;
}

.closed-block a {
  color: #000;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  margin: 0;
  color: #1b1a20;
  font-family: 'Roboto', sans-serif; }

main {
  display: flex;
  padding: 20px 2%;
  max-width: 1200px;
  margin: 50px auto 0 auto; }

@media only screen and (max-width: 1024px) {
  body .filters {
    width: 20%; }
  body .shelf-container {
    width: 80%; }
    body .shelf-container .shelf-item {
      width: 33.33%; } }

@media only screen and (max-width: 640px) {
  body .filters {
    width: 25%; }
  body .shelf-container {
    width: 75%; }
    body .shelf-container .shelf-item {
      width: 50%;
      padding: 10px; }
      body .shelf-container .shelf-item__title {
        margin-top: 5px;
        padding: 0; }
  body .float-cart {
    width: 100%;
    right: -100%; }
    body .float-cart--open {
      right: 0; }
    body .float-cart__close-btn {
      left: 0px;
      z-index: 2;
      background-color: #1b1a20; }
    body .float-cart__header {
      padding: 25px 0; } }

@media only screen and (max-width: 460px) {
  body main {
    display: flex;
    flex-wrap: wrap;
    padding: 2%;
    margin-top: 42px; }
  body .filters {
    width: 100%;
    margin-right: 0;
    text-align: center; }
    body .filters .title {
      margin-bottom: 15px; }
  body .shelf-container-header .products-found {
    width: 100%;
    text-align: center;
    margin: 10px 0; }
  body .shelf-container-header .sort {
    width: 100%;
    text-align: center; }
  body .shelf-container {
    width: 100%; }
    body .shelf-container .shelf-item {
      width: 50%; }
      body .shelf-container .shelf-item__buy-btn {
        display: none; } }

